.feature-name {
    text-align: start !important;
    color: #000;
}

.plan-name {
    font-weight: 700;
}

.branding-essentials {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.package-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.package-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.package-features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.package-features li {
    margin-bottom: 8px;
}

.package-button {
    width: 100%;
}


.branding-essentials {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.row {
    margin: 0;
    /* Quita el margen del row */
}

.col-12,
.col-sm-6,
.col-md-3 {
    padding: 0;
    /* Quita el padding entre columnas */
}

.container-plan-card {
    width: 100%;
    /* Ocupa el ancho completo de la columna */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    background: white;
}