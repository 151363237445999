@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

html {
    margin: 0;
    border: 0;

}

* {
    font-family: 'Poppins';
}

ul {
    list-style-type: none;
}

body {
    background: #fff;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

footer {
    background-color: #000;
}

li {

    font-size: 13px;
    text-align: center !important;
}

a {
    text-decoration: none;
    color: inherit;
}


.lower {
    font-size: 30px;
}


/* Añadir esto a tu archivo CSS global */
.elfsight-app [href*="elfsight.com"] {
    display: none !important;
}



@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    70% {
        background-position: 100% 70%;
    }

    100% {
        background-position: 0% 50%;
    }
}


.h-screen {
    height: 70vh;
}

.logo_container_nav_color {
    width: 300px;
    height: 120px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../assets/images/047_Logo-desarrollalab-redesigned-black.png);
}

#heroPage {
    background: url(../assets/images/019_background_programming_computer.jpg);

    background-size: cover;
    width: 100%;
    background-position: top;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
    min-height: 600px;
}

#heroBackgroundGetInTouch {

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.575), rgba(0, 0, 0, 0.3)), url(../assets/images/021_contacto.jpg);
    background-size: cover;
    width: 100%;
    min-height: 400px;
    background-position: center;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
}

#heroPaginasWeb {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.575), rgba(0, 0, 0, 0.3)), url(../assets/images/022_paginas_web.png);
    background-size: cover;
    width: 100%;
    min-height: 400px;
    background-position: center;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
}

#heroSeguridadWeb {
    background: url('https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/bg-seguridad-web.png?alt=media&token=f9de40df-74a1-4267-bfa8-519652b0a62b');
    background-size: cover;
    width: 100%;
    min-height: 400px;
    background-position: center;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
}

#heroEcommerce {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.575), rgba(0, 0, 0, 0.3)), url(../assets/images/033_ecommerce_hero.jpg);
    background-size: cover;
    width: 100%;
    min-height: 400px;
    background-position: center;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
}

#heroPage {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/040_home_bg.c83b2d4f83663411bd20.webp?alt=media&token=b1209965-1ba1-4824-b6db-4b2dae6cee6a");
    background-size: cover;
    width: 100%;
    background-position: top;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
    min-height: 600px;
}

#heroPortfolioPc {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/bg-pc-portfolio-pc.webp?alt=media&token=2b50989a-8c47-4e78-bea8-8b6ae3f75283");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: top;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
    min-height: 600px;
}

#heroPortfolioMb {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/bg-pc-portfolio-pc.webp?alt=media&token=2b50989a-8c47-4e78-bea8-8b6ae3f75283");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: 65%;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
    min-height: 600px;
}

#heroAppsWeb {
    background: url(../assets/images/043_apps_webs_bg.jpg);
    background-size: cover;
    width: 100%;
    background-position: top;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
    min-height: 600px;
}

.heroImage {
    background: url(../assets/images/002_banner_main.jpg);
    background-size: contain;

    background-position: center;
    height: 500px;
    width: 50%;
}





.heroContact {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Hablemos.svg?alt=media&token=89e618b8-2e4d-49b0-9bc5-a66bdfaa32c3");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    width: 50%;
}

.heroPortafolio {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Portafolio.svg?alt=media&token=ab1d78bd-7326-40d0-9724-b48806caafd2");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    width: 50%;
}

.heroSolutions {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/Desarrollo%20de%20software.svg?alt=media&token=2138d249-96ba-492b-92fe-be7a873b991e");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    width: 50%;
}

.heroGetInTouch {
    background: url(../assets/images/018_hablemos_background.jpg);
    background-size: cover;
    background-position: center;
    height: 500px;
    width: 50%;
}



#heroAgendarCita {

    background: linear-gradient(150deg, rgba(233, 233, 233, 0), rgba(206, 0, 148, 0.643)), url(../assets/images/046_book_consultationhd.jpg);
    background-size: cover;
    width: 100%;
    min-height: 400px;
    background-position: center;
    display: flex !important;
    align-items: center !important;
    padding: 2%;
}


.container-happy-you-project {


    background: url(../assets/images/011_background_happy.svg);
    background-size: cover;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
}


.container-were-happy {


    justify-content: center !important;
    width: 65%;
    padding: 2%;
}

/* Texts */

.heroTitle {
    font-size: 50px;
    color: #fff;
    text-align: left;
}

.title-black {
    font-size: 40px;
    color: #000;
    text-align: center;
}

.title-brands {
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.title-brands-hero {
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    text-align: left;
}

.title-white {
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    text-align: left;
}

.title-small-white {
    font-size: 20px;
    color: #fff;
    text-align: left;
    font-weight: 100;
}

.parraf-text-white {
    color: #fff;
    text-align: left;
    font-size: 13px;
}

.service-title {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    text-align: center;
}

.caption-text {
    font-size: 24px;
    color: rgb(43, 43, 43);
    text-align: center;
    font-weight: 400;
}

.caption-text-purple {
    font-size: 24px;
    color: #ff0077;
    text-align: center;
    font-weight: 400;
}

.purple-text-global {
    font-size: inherit;
    color: #c00342;
    text-align: center;
    font-weight: 600;
}

.service-name {
    font-size: 15px;
    text-align: center !important;
}

.text-dark {
    color: #000 !important;
}

.success-stories {
    color: #c00342;
    font-size: 13px;
}

.fs-6 {
    font-size: 13px !important;
}

.txt-banner-white {
    background: #000;
}

/* Buttons */
.btn-cotizar {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

.btn-cotizar:hover {
    background-color: #fff;
    color: #690031;
}

.btn-more-info {

    width: 300px;
    align-self: center;
    background-color: #ff0077;
    border-radius: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    min-height: 50px;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);


    -webkit-box-shadow: 0px 10px 49px -9px rgba(255, 0, 119, 0.67);
    -moz-box-shadow: 0px 10px 49px -9px rgba(255, 0, 119, 0.67);
    box-shadow: 0px 10px 49px -9px rgba(255, 0, 119, 0.67);
}

.btn-more-info:hover {
    color: #ff0077;
    background-color: #fff;
    border: 2px solid #ff0077;
}

.btn-transparent-outlet-white {
    border: 1px solid #fff;
    color: #fff;
    border-radius: 5px;
    width: 250px;
}

.btn-transparent-outlet-white:hover {
    color: #690031;
    background-color: #fff;
}

/* Components */
.active {
    background-color: #FF0177 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #db287c;
}

.nav-link {
    color: #690031;
}

.nav-link:hover {
    color: #690031;
}

/* cards */
.card-service {
    border: 1px solid #afafaf77;
    border-radius: 10px;
    padding: 20px;
}

.card {
    background-color: #fff;

    border-radius: 15px;

    -webkit-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.16);
}

.card {
    border: 0 !important;
}

.card-text {
    font-size: 13px;
    color: #6b7a7e;
}

.card-link {
    color: #690031;
}

/* Images */

.image-caption-web-service {
    width: 300px;
    height: 300px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background: url(../assets/images/006_Desarrollo-Web.png);
}

.image-caption-mobile-service {
    width: 300px;
    height: 300px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background: url(../assets/images/007_desarrollo_movil.png);
}

.image-caption-software-service {
    width: 300px;
    height: 300px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background: url(../assets/images/009_sotware_multiplataforna.png);
}

.mockup {
    width: 500px;
    background-size: 100%;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
}

.col-mkp {
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
}

.customer-logos {
    background-color: #111;
}

/* Icons */
.testimonial-icon {
    width: 100px;
    height: 100px;
    position: relative;
    align-self: flex-end;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/images/014_testimonial_icon.svg);
}


.techBehemots {
    width: 150px;
    height: 100px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/TB-trusted-on-transparent-dark-bg.svg?alt=media&token=10a12539-7d31-4080-94a7-fe603c54fedb");
}


.goodFirms {
    width: 150px;
    height: 100px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://assets.goodfirms.co/badges/blue-button/top-software-development-companies.svg");
}

.clutchCo {
    width: 110px;
    height: 70px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/2-reviews-clutch-svg.svg?alt=media&token=f01d9a64-c7f5-493a-8980-0525c557b301");
}

.stamp-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border: 1px solid black;
}

.stamp-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.stamp-text {
    margin-top: 10px;
}


.award-custom-software {
    width: 260px;
    height: 150px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/awards-black%2Ftb-custom-software.png?alt=media&token=c715593d-86f8-4425-9e39-fa3551912c2c");
}

.award-web-development {
    width: 260px;
    height: 150px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/awards-black%2Ftb-web-development.png?alt=media&token=2140329e-25c6-403d-8101-7bcc8752a0d2");
}

.award-uxui {
    width: 260px;
    height: 150px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/awards-black%2Ftb-ux-ui.png?alt=media&token=78604248-e8bf-4856-a9f7-6279b42adee3");
}

/* Slider */

.section-padding {
    padding: 20px 0;
}

.brand-carousel {
    background: aliceblue;
    margin-top: 4%;
}

.owl-dots {
    text-align: center;
    margin-top: 4%;
}

.owl-dot {
    display: inline-block;
    height: 15px !important;
    width: 15px !important;
    background-color: #a8a8a877 !important;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
}

.owl-dot.active {
    background-color: #bd0359 !important;
}

.brand-logo {
    width: 40% !important;
    align-self: center;
}


.dotted-world {
    background: url(../assets/images/0016_dotted_background_world.svg);
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}


.col-banner-world-projects {
    background-color: #c00342;
    display: flex;
    align-items: center;
}

/* Footer */

.logo_desarrollalab_footer {
    background-image: url(../assets/images/048_Logo-desarrollalab-redesigned-white.png);
    width: 300px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
}


/* Formulario de contacto */
.card-form {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    -webkit-box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
}

.small-label {
    font-size: 13px;
    color: #1d1d1d;
}

.container-form-description {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-form {
    background-color: #fff;
    height: auto;
}


.transparent-border-input {
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    height: 50px;
    width: 100% !important;
    color: #4f4f4f;
    padding: 1.5em;

    box-shadow: 0px 10px 38px -25px rgba(0, 0, 0, 0.1);
}

.transparent-border-input:focus {
    outline: none !important;
    background-color: rgb(255, 255, 255) !important;
}

.form-select-rounded-transparent {
    border: none;
    border-radius: 50px;
    height: 50px;
    color: #4f4f4f;
    box-shadow: 0px 10px 38px -25px rgba(0, 0, 0, 0.1);
}

.form-select-rounded-transparent:focus {
    outline: none;
    background-color: rgb(255, 255, 255) !important;
}

.navbar-toggler:focus {
    outline: none !important;
}

.transparent-border-input::placeholder {
    color: rgb(39, 39, 39);
}

.transparent-border-textarea {
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    height: 80px;
    width: 100%;
    color: #4f4f4f;
    padding: 1.5em;
    box-shadow: 0px 10px 38px -25px rgba(0, 0, 0, 0.1);

}


.transparent-border-textarea:focus {
    outline: none;
    background-color: rgb(255, 255, 255) !important;
}

.transparent-border-textarea::placeholder {
    color: rgb(39, 39, 39);
}


.btn-send {
    width: 90%;
    background-color: #ff0177;
    height: 50px;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.btn-solicitar-info {

    background: rgb(159, 0, 53);
    background: linear-gradient(344deg, rgba(159, 0, 53, 1) 0%, rgba(255, 1, 119, 1) 96%);
    min-width: 200px;
    align-self: center;
    border-radius: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;

    -webkit-box-shadow: 0px 10px 27px -3px rgba(255, 1, 119, 0.42);
    -moz-box-shadow: 0px 10px 27px -3px rgba(255, 1, 119, 0.42);
    box-shadow: 0px 10px 27px -3px rgba(255, 1, 119, 0.42);

    transition: all 0.3s ease;
    /* Transición de 0.3 segundos con efecto suave */

}

.btn-solicitar-info:hover {
    color: #fff;
}

.c-form {
    width: 100% !important;
}


.text-left {
    text-align: left !important;
}

/* Client logos */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px *7));
    }
}

.slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
}

.slider .slide-track {
    animation: scroll 20s linear infinite;
    display: flex;
    width: calc(250px* 14);
}

.slider .slide {
    height: 100px;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* Card service */

.container-card-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service-image {
    width: 300px;
    margin: 10%;
    align-self: center;
}

.book-consulting-img-service {
    width: 400px;
    margin: 10%;
    align-self: center;
}

.service-title {
    font-size: 26px;
    font-weight: 600;
    color: #000;
}

.service-description {
    font-size: 15px;
    color: #545456;
    line-height: 1.8;
    text-align: left;
    margin-bottom: 5%;
}


.border-col {
    border-right: 1px solid #a3a3a3;
    border-bottom: 1px solid #a3a3a3;
    height: 150px;
    display: flex;
}

.title-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hight-space {
    margin-top: 40px;
    margin-bottom: 40px;
}

/* spacer */
.spacer {
    height: 2rem;
}

/* Custom */
/* * {
    border:1px solid red;
} */

.rounded-2xl {
    border-radius: 7%;
}

.btn-hide-example {
    background-color: #efefef !important;
    width: 200px;
    padding: 5px;
    border-radius: 50px;
    color: #000;
}

.preview-website {
    width: 300px;
    height: '400px';
    direction: 'rtl';
    background-color: #f5f5f5;
}

.mini-website {
    transform: 'scale(0.28, 0.28) translate(1258px, -865px)';
    width: '1000px';
    height: '700px';
    border-radius: '7%';
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.circle-list {
    width: 15px;
    border: 2px solid #ff0177;
    border-radius: 100%;
    height: 15px;
    margin: 3px;
}

.bg-light-gray {
    background-color: #f5f5f5 !important;
}

.h-200 {
    height: 200px;
}

.text-left {
    text-align: left !important;
}

.my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.left-image-hero {
    height: 100%;
    width: 100%;
}

.bg-dark-blue {
    background-color: #110040 !important;
}

.text-purple-mexican {
    color: #ff0177 !important;
}

.mb-5 {
    margin-bottom: 5rem !important;
}

.bred {
    border: 1px solid red !important;
}

.smallText {
    font-size: 15px;
}


.underline_red {
    border-bottom: 2px solid #ff0177;
    padding: 2px;
}

.bg-light {
    background-color: #fff !important
}

.navbar-light {
    background-color: #fff !important
}

/* Wabutton */
.wp-button {
    margin-top: 14px;
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(37, 211, 102, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
    }
}


.brand-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.brand-logo {
    max-width: 100%;
    /* Tamaño uniforme */
    height: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.brand-logo:hover {
    transform: scale(1.1);
}


.wa-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1000;
}

.wa-button {
    background-color: #25d366;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    font-size: 24px;
    transition: background 0.3s ease-in-out;
}

.wa-button:hover {
    background-color: #1ebe5d;
    color: white;
}

.wa-label {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
}


.dropdown-menu {

    background: rgba(28, 28, 28, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(28, 28, 28, 0.03);

    padding: 0%;
    outline: none !important;
    border-radius: 5px !important;
    width: 200px;


    animation: fadeInAnimation ease 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    -webkit-box-shadow: 0px 10px 29px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 0px 10px 29px 0px rgba(0, 0, 0, 0.32);
    box-shadow: 0px 10px 29px 0px rgba(0, 0, 0, 0.32);
}

.dropdown-item {
    color: #fff;
    border-radius: 5px !important;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.dropdown-item:hover {
    color: #fff;
    background: #db287c;
    outline: 1px solid #db287cb0;
}


/* Include service icons */

.desarrollo-web-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2Freact-logo.4b8c987d.svg?alt=media&token=043895d9-d93d-4451-8f53-3f4ac6861d55");
    background-size: contain;
    background-repeat: no-repeat;
    width: 80%;
    height: 50px;
    margin: 0.5em;
}

.diseno-web-icon {
    background: url(../assets/images/023_Diseno-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.cloud-icon {
    background: url(../assets/images/024_cloud_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.speed-icon {
    background: url(../assets/images/025_speed.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.phone-icon {
    background: url(../assets/images/026_phone_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.dominio-icon {
    background: url(../assets/images/027_dominio_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.ssl-icon {
    background: url(../assets/images/028_ssl_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.imagestock-icon {
    background: url(../assets/images/029_image_stock.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.payment-icon {
    background: url(../assets/images/032_payment_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.cart-icon {
    background: url(../assets/images/034_cart_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.inventory-icon {
    background: url(../assets/images/035_inventory_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.check-icon {
    background: url(../assets/images/036_check_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}

.support-icon {
    background: url(../assets/images/037_support_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40%;
    height: 50px;
    margin: 0.5em;
}


/* End include service icons */

/* Include service card */
.container-include-serviceCard {
    display: flex;
    flex-direction: row;
    margin: 10px;
}


.includeTitle {
    font-size: 19px;
}

.includeDescription {
    font-size: 14px;
    text-align: justify;

}

/* Container card plan */

.container-plan-card {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid gray;
    width: 400px;
    -webkit-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.16);
}

.container-namePlan {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.plan-name {
    font-size: 17px;
    color: #000;
}

.container-plan-price {
    display: flex;
    justify-content: center;
}

.plan-price {
    font-weight: 600;
    font-size: 30px;
    color: #ff0177;
}

.pay-one {
    font-size: 15px;
}

.plan-description {
    font-weight: 600;
    color: #000;
}

.work-days {
    text-align: center;
    font-size: 13px;
}

.no-iva {
    color: rgb(179, 179, 179);
}

/* Scrollbutton */


#myBtn {
    display: none;
    position: fixed;
    bottom: 40px;
    left: 100px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: rgba(54, 54, 54, 0.452);
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;

    animation: fadeInAnimation ease 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#myBtn:hover {

    background-color: rgba(54, 54, 54, 0.767);
}

.up-icon {
    background: url(../assets/images/030_up_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
}

.li-plan {
    color: rgb(20, 20, 20);
    text-align: left !important;
    margin-bottom: 8px;
}

.dropdown-toggle:hover {
    color: #690031 !important;
}

.dropdown-toggle:focus {
    color: #690031 !important;
}

.avatar-icon {
    background: url(../assets/images/031_user_icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    margin: 0.5em;
}

.bannerHero {
    padding: 1em;
}

.line-footer-col {
    border-right: 1px solid #fff;
}

/* Testimonial */

.card-testimonial {

    border: 1px solid #e4e4e4;
    height: 280px;
    display: flex;
    flex-direction: row;
    background: linear-gradient(100.6deg, #FFF1FD 0.7%, #E6F7FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.card-text {
    color: #000;
}

.bg-gray {
    background: #F1F1F1;
}

.avatar-container {
    display: flex;
    align-items: center;
}


.techbehemoths-container {
    background: url(../assets/images/038_TB-trusted-on-transparent-light-bg.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    margin-top: 10% !important;
    margin-bottom: 5%;
}

.container-text-techbehemots {
    min-height: 400px;
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    background-color: #000;
}

/* World dots */

.container-world-dots {
    padding: 1em;
    background: linear-gradient(101.81deg, #8B0A46 0%, #D80064 91.36%);
    min-height: 350px;
}

.world-dots {
    background: url(./images/042_world_dots_2.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 350px;

}


/* Apps web */
.software-app-description-bg {
    background: url(./images/044_software_app_description_bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.container-devops {
    padding: 1em;
}

.devops-bg {
    background: url(./images/045_devops_cycle.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
}

.badge-number {
    background-color: #FF0177;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-align: center;
}

.card-no-shadow {
    background-color: rgb(242, 242, 242);
}


/* Portfolio */


.portfolioBannerText {
    width: 70%;
    background-color: #6b0077;
    background-image: linear-gradient(322deg, #6b0077 0%, #ff0045 50%, #e20067 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.purple-gradient-text {
    background-color: #6b0077;
    background-image: linear-gradient(322deg, #6b0077 0%, #ff0045 50%, #e20067 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bannerWeLoveOurWork {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/iStock-1392637699.jpg?alt=media&token=5b4c9eda-9658-4807-8098-59331fb6ad94");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(179, 50, 169, 0.25));
    border-radius: 10px;
}

.custom-gray-container-loveWork {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(218, 218, 218, 0.25) 100%);
    border-radius: 10px;
}

/* Ends Portfolio */

/* Card mockup */
.card-mockup-logo-empresa {
    height: 200px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.linear-gray-background {
    background: linear-gradient(63.68deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100.77%);
}

.container-project-description {
    padding: 1em;
    background: linear-gradient(180deg, #F9F9F9 0%, rgba(249, 249, 249, 0) 100%);
    filter: drop-shadow(0px 20px 20px rgba(114, 114, 114, 0.25));
    border-radius: 10px;
}


.bg-glass {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.27);
    box-shadow: 0px 4px 4px rgba(226, 240, 255, 0.25);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.hideScrollbar::-webkit-scrollbar {
    border: none;
}

.hideScrollbar::-webkit-scrollbar-thumb {
    background: rgba(71, 71, 71, 0.27);
    border-radius: 4px;
    border: 0.70px solid rgb(207, 207, 207);
}

/* Ends Card mockup */



/* Full navegación menu */
.container-full-space {
    animation: fadeIn 1s;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Ends Full navegación menu */

/* DISCOUNT */

.text-gray-discount {
    color: #a6a6a6;
    font-size: 25px;
    font-weight: bold;
    text-decoration: line-through;
}

/* ENDS DISCOUNT */



/* Not found */

.not-found-image {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/undraw_by_the_road_re_vvs7.svg?alt=media&token=9d7ff3e9-40ba-422d-898d-8006ed405010");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    height: 300px;
    width: 300px;
}

/* Ends Not found */


/* APPS MOVILES */






/* Card hover */

.card-box {
    border: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    perspective: 1000px;
}

.card-box .card {
    position: relative;
    border-radius: 30px !important;
    width: 350px;
    height: 400px;
    transform-style: preserve-3d;
    transition: 600ms ease;
}

.card-front,
.card-back {
    position: absolute;
    border-radius: 30px !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    background-color: #FF3CAC;
    background-image: linear-gradient(177deg, #ff3caac2 0%, #784ba089 50%, #2b85c5aa 100%), url('https://images.unsplash.com/photo-1551721434-8b94ddff0e6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=465&q=80');
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-box-shadow: 0px 35px 55px -7px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 35px 55px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 35px 55px -7px rgba(0, 0, 0, 0.2);
}

.card-back {
    background-color: #FF3CAC;
    background-image: linear-gradient(177deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    -webkit-box-shadow: 0px 35px 55px 8px rgba(0, 136, 255, 0.37);
    -moz-box-shadow: 0px 35px 55px 8px rgba(0, 136, 255, 0.37);
    box-shadow: 0px 35px 55px 8px rgba(0, 136, 255, 0.37);
}

.card-box:hover .card {
    transform: rotateY(180deg);
}

/* Ends Card hover */


/* platform icons */

.apple-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2FApple_logo.1c86d499.svg?alt=media&token=72d7bcf6-a244-4a9a-91c5-116418707985");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
}

.android-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2Fandroid-logo.bda960dd.svg?alt=media&token=d7cf7591-2c08-4f9b-a972-2c25e10aa465");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
}

.swift-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2Fswift-logo.4832a04e.svg?alt=media&token=537d45b6-f1ae-4e98-b4b6-2024a1e2df51");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
}

.flutter-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2Fflutter-logo.48531743.svg?alt=media&token=068fa756-b450-4ffa-b65c-0fc3787bf163");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
}

.react-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2Freact-logo.4b8c987d.svg?alt=media&token=043895d9-d93d-4451-8f53-3f4ac6861d55");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
}

.ionic-icon {
    background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/icons%2Fionic-logo.32aa1c92.svg?alt=media&token=18a5deae-2bde-4c4f-8473-e455e1b8efb9");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 50px;
}

/* Ends platform icons */

.why-jire-us {
    background-color: #ffffff;
    /* background image with linear gradient */
    background-image: linear-gradient(177deg, #ff3caa8a 0%, #784ba038 50%, #2b85c5c1 100%), url('https://images.unsplash.com/photo-1498036882173-b41c28a8ba34?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80');
    padding: 50px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    width: 100%;
}

/* ENDS APPS MOVILES */



/* SEGURIDAD WEB */
.logo-carrusel {
    width: 150px;
    height: 130px;
    object-position: center;
    object-fit: contain;
}

.bg-dark-purple-gradient {
    background: linear-gradient(284deg, #942896 -19.34%, #060545 91.58%);
}


.desarrollalab-text-gradient {
    letter-spacing: 59.5px;
    font-size: 35px;
    background: linear-gradient(180deg, #E4156C 0%, #360981 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.modal-subscription {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.m-modal-content {
    background-color: #fff;
    border-radius: 20px;
    border: 0px;
}

.modal-backdrop {
    background-color: transparent;
}

.small-xl {
    font-size: 10px;
    text-align: center;
    line-height: 3px;
}

.bg-glass-purple {
    /* From https://css.glass */
    background: rgba(95, 10, 66, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}


.headerComprobantePago {
    background: #E4156C;
    background-size: cover;
    height: 50px;
    width: 100%;
}


.bg-gray-50 {
    background-color: #f9f9f9;
}

.fade-div {
    animation: fadeIn 300ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* ENDS SEGURIDAD WEB */


.slide-carousel-image {
    width: 98vh;
    height: "auto";
    border-radius: 10px;
}

.text-sm{
    font-size: 14px;
}
.fw-medium{
    font-weight: 600;
}
/* Responsive */
@media only screen and (max-width: 1000px) {

    .lower{
        font-size: 20px !important;
        font-weight: 600;
    }
    .slide-carousel-image{
        width: 340px;
    }

    .h-screen {
        height: 50vh;
    }

    .book-consulting-img-service {
        width: 300px;
        margin: 10%;
        align-self: center;
    }

    .desarrollalab-text-gradient {
        letter-spacing: 10px;
        font-size: 25px;
        background: linear-gradient(180deg, #E4156C 0%, #360981 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .card-box .card {
        width: auto;
        height: 400px;
        margin-bottom: 2em;
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }

    .slick-arrow {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

    #heroPage {
        display: block !important;
        min-height: 450px !important;
    }

    #heroBackgroundGetInTouch {
        display: block !important;
    }

    .service-image {
        margin: auto
    }

    .service-title {
        margin-top: 30px;
    }

    .heroTitle {
        font-size: 35px;
    }

    .title-black {
        font-size: 35px;
    }

    .title-brands {
        font-size: 26px;
    }

    .title-brands-modal {
        font-size: 22px;
    }

    .mockup {
        width: 330px;
    }

    .caption-text-purple {
        font-size: 16px;
    }

    .caption-text {
        font-size: 16px;
    }

    .container-form-description {
        height: auto;
    }

    .my-6 {
        margin-top: 20px !important;
        margin-bottom: 40px !important;
    }

    #pc {
        display: none !important;
    }

    #mb {
        display: block !important;
    }

    .container-description-header {
        width: 100%
    }

    .container-content {
        position: relative;
        top: 10px;
        padding: 1em;
    }

    .brand-logo {
        width: 90% !important;
        padding: 10px !important;
    }

    .dropdown-menu {
        width: 100%;
    }

    .nav-container {
        height: 120px;
    }

    .line-footer-col {
        border-bottom: 1px solid #fff;
        border-right: none;
        margin-bottom: 1em;
        padding-bottom: 1em;
    }

    .card-testimonial {
        height: auto;
    }

    .btn-more-info {
        width: auto;
    }


    #heroPortfolio {
        min-height: 400px;
    }

    .software-app-description-bg {
        height: 200px;
    }

    .devops-bg {
        height: 200px;
    }

}

#mb {
    display: none;
}

.review-calif {
    font-weight: 600;
    font-size: 20px;
}

.txt-reviews-clutch {
    color: #EF4335;
    text-align: center;
    font-size: 11px;
}

.container-calif {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: center;
}

.badget-proweredbyclutch {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17313B;
    color: #fff;
    font-size: 12px;
    width: 100%;
}

.logo-clutch {
    margin-left: 3px;
    height: 20px;
    object-position: center;
    object-fit: contain;
}

.txt-poweredby {
    color: #fff;
}

.review-c-body {
    width: 100%;
}

.star-c-review {
    height: 15px;
    width: 15px;
    margin: 3px;
}

.container-review {
    display: block;
    background-color: #fff;
    width: 150px;
    border: 5px !important;
    height: auto;
}

.no-style-a:hover {
    color: #000 !important;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* animations */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index: 100;
}

/* WRAPPERS */
#wrapper {
    width: 100%;
    overflow-x: hidden;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
}

.wrapper {
    padding: 0 20px;
}

.wrapper-content {
    padding: 0px;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(40px);
        -ms-transform: translateX(40px);
        transform: translateX(40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}


.h-100 {
    height: 90vh !important;
}

@media screen and (max-width:1000px) {
    .h-100 {
        height: auto !important;
        width: 300px;
    }
}

/* Fade in left */


@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-00px);
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-30px);
        -ms-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}




/* Fade in up */

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* styles.css */
.fade-in {
    opacity: 0;
    transform: translateY(20px);
    /* Empieza ligeramente desplazado hacia abajo */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in.active {
    opacity: 1;
    transform: translateY(0);
}



/* Portfolio 2024 Ago */

.linea-hero {
    position: absolute;
    bottom: 16%;
    /* Ajusta este valor según sea necesario */
    left: 44.5%;
    height: 120px !important;
    /* Ajustar altura si es necesario */
    width: auto !important;
    /* Asegura que cubra todo el ancho debajo del cuadro */
    z-index: 100;
    object-fit: cover;
}


.title-hero-portfolio {
    margin-top: 4em !important;
}


.prototype {
    width: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.proyecto {
    position: absolute;
}

.contenedor {
    padding-top: 100px;
    padding-bottom: 100px;
}

@media (max-width: 768px) {
    .proyecto {
        position: relative;
        font-size: 18px;
        margin-top: -6px;
    }

    .nombre {
        margin-bottom: 15px;
    }
}


.hero-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/desarrollalab%2Fbg-off-portafolio.png?alt=media&token=edbf8d9e-147c-4217-af34-6d1586a77c03');
    background-size: cover;
    background-position: top;
}

.hero-content {
    color: white;
    text-align: left;
    padding: 20px;
    width: 100%;
    position: relative;
    top: 30%;
}

.titulo {
    margin-top: -200px;
    margin-left: -50px;
}


.highlight {
    color: #E91E63;
}

.hero-button {
    background: #ffffff4f;
    border: none;
    font-size: x-large;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    margin-bottom: 1rem;
    border: 2px solid #E91E63;
}

.hero-subtext {
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 0px;
    max-width: 700px;
}

.hero-footer {
    width: 100%;
    background: #89093e96;
    padding: 10px;
    margin-top: 60px;
    font-size: 16px;
    text-align: center;
    max-width: 580px;
    margin-left: -70px;
}

.font-light {
    font-size: 20px;
    font-weight: lighter;
}

@media (max-width: 768px) {
    .hero-container {
        width: 100%;
        height: 69vh;
    }

    .hero-content {
        top: 40%;
    }

    .titulo {

        margin-left: 20px;
    }


    .hero-subtext {
        background: rgba(0, 0, 0, 0.7);
        padding: 10px;
        margin-left: 10px;
        font-size: smaller;
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 30px;
    }

    .hero-footer {
        background: #89093e96;
        padding: 10px;
        margin-top: 20px;
        font-size: smaller;
        text-align: center;
        max-width: 580px;
        margin-left: 10px;
    }
}


@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    will-change: opacity, transform;
}

.fade-in-up.in-view {
    opacity: 1;
    transform: translateY(0);
}